.hpigfk {
  height: 800px !important;
}

#pdf-download {
  display: none !important;
}
.rpv-core__canvas-layer{
  box-shadow:none !important;
}
.rpv-core__text-layer{
  box-shadow:none !important;

}