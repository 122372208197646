// Here you can add other styles

.table-spinner{
    margin-left: 50%;
}

.page-spinner{
    margin-left: "50%";
    height: "5rem";
    width: "5rem";
    margin-top: "10%";
}